import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function typesList() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refTypesTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", label: "Tên Đường dùng", sortable: false },
    { key: "created_at", label: "Ngày tạo", sortable: false },
    { key: "action", label: "Hành động", class: "text-right", sortable: false },
    // { key: 'updatedAt', sortable: false },
  ];
  const perPage = ref(10);
  const totalTypes = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50];
  const searchQuery = ref("");

  const dataMeta = computed(() => {
    const localItemsCount = refTypesTable.value
      ? refTypesTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTypes.value,
    };
  });

  const refetchData = () => {
    refTypesTable.value.refresh();
  };
  const fetchCategories = (ctx, callback) => {
    store
      .dispatch("pharmaUseType/fetchUseTypes", {
        keyword: searchQuery.value,
        page_size: perPage.value,
        page_num: currentPage.value,
        sort_by: "id",
        order: "desc",
      })
      .then((response) => {
        const useTypes = response.data.data;
        totalTypes.value = response.data.page?.total || 0;
        callback(useTypes);
      })
      .catch((e) => {
        console.log(e);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching general config list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const timeout = ref(null);
  watch([currentPage, perPage, searchQuery], () => {
    showTable.value = true;
    clearTimeout(timeout.value);
    timeout.value = setTimeout(() => {
      refetchData();
      showTable.value = false;
    }, 600);
  });
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    timeout,
    showTable,
    fetchCategories,
    tableColumns,
    perPage,
    currentPage,
    totalTypes,
    dataMeta,
    perPageOptions,
    searchQuery,
    refTypesTable,
    refetchData,
    // Extra
  };
}
