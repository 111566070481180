<template>
  <b-sidebar
    id="add-new-brands-sidebar"
    :visible="isAddNewPharmaUseType"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-pharma-use-type', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Thêm mới Đường dùng</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Name -->
          <validation-provider #default="validationContext" name="Tên" rules="required">
            <b-form-group label="Tên" label-for="Name">
              <b-form-input
                id="name"
                v-model="tempData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Tên"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Mã" label-for="code">
              <b-form-input
                id="code"
                v-model="tempData.code"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Mã"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >Thêm</b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >Hủy</b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as optionData from "@/utils/optionsData";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: "isAddNewPharmaUseType",
    event: "update:is-add-new-pharma-use-type"
  },
  props: {
    isAddNewPharmaUseType: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      required
    };
  },

  setup(props, { emit }) {
    const toast = useToast();
    const blankData = {
      name: "",
      code: ""
    };

    const tempData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      tempData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      const payload = {
        name: tempData.value.name,
        code: tempData.value.code
      };
      store
        .dispatch("pharmaUseType/addUseType", payload)
        .then(() => {
          emit("refetch-data");
          emit("update:is-add-new-pharma-use-type", false);
        })
        .catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData
    );

    return {
      toast,
      tempData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm
    };
  }
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-brands-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
