import { render, staticRenderFns } from "./PharmaUseTypeUpdate.vue?vue&type=template&id=23bbf934"
import script from "./PharmaUseTypeUpdate.vue?vue&type=script&lang=js"
export * from "./PharmaUseTypeUpdate.vue?vue&type=script&lang=js"
import style0 from "./PharmaUseTypeUpdate.vue?vue&type=style&index=0&id=23bbf934&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports