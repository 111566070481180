export const unitOptions = [
  {
    value: 32,
    text: "Viên",
  },
  {
    value: 30,
    text: "Tuýp",
  },
  {
    value: 29,
    text: "Túi/chai/lọ",
  },
];
export const activeIngredientOptions = [
  {
    value: 1,
    text: "Atropin sulfat",
  },
  {
    value: 2,
    text: "Bupivacain (hydroclorid)",
  },
  {
    value: 3,
    text: "Dexmedetomidin",
  },
];
export const routeOfUse = [
  {
    value: 1,
    text: "Uống",
  },
  {
    value: 2,
    text: "Ngậm",
  },
  {
    value: 3,
    text: "Đặt dưới lưỡi",
  },
];
export const countryOptions = [
  {
    value: 1,
    text: "Việt Nam",
  },
  {
    value: 2,
    text: "Pháp",
  },
  {
    value: 3,
    text: "Hàn Quốc",
  },
];
export const manufacturerOptions = [
  {
    value: 1,
    text: "DOMESCO-Việt Nam",
  },
  {
    value: 2,
    text: "KORA pharma city",
  },
  {
    value: 3,
    text: "BETAMON VRINAM France",
  },
];
export const pharmaSupplier = [
  {
    value: 1,
    text: "Công ty Dược phẩm Nam Hà",
  },
  {
    value: 2,
    text: "Công ty Dược phẩm Trung Ương",
  },
];
export const pharmaProductTypes = [
  {
    value: 1,
    text: "Dịch truyền",
  },
  {
    value: 2,
    text: "Khoáng chất và vitamin",
  },
  {
    value: 3,
    text: "Mỹ phẩm",
  },
  {
    value: 4,
    text: "Nhóm corticoid",
  },
  {
    value: 5,
    text: "Sinh phẩm",
  },
  {
    value: 6,
    text: "Thực phẩm chức năng",
  },
];
export const medicineTypes = [
  {
    value: 1,
    text: "Tân dược",
  },
  {
    value: 2,
    text: "Chế phẩm",
  },
  {
    value: 3,
    text: "Vị thuốc",
  },
  {
    value: 4,
    text: "Phóng xạ",
  },
  {
    value: 5,
    text: "Tân dược tự bào chế",
  },
  {
    value: 6,
    text: "Chế phẩm tự bào chế",
  },
];
export const biddingTypesOptions = [
  {
    value: 1,
    text: "Thầu tập trung",
  },
  {
    value: 2,
    text: "Thầu riêng tại cơ sở KCB",
  },
  {
    value: 3,
    text: "Tự pha chế, bào chế, chế biến",
  },
  {
    value: 4,
    text: "Mua sắm theo kết quả trúng thầu đơn vị khác",
  },
];
export const biddingForms = [
  {
    value: 1,
    text: "Đấu thầu rộng rãi",
  },
  {
    value: 2,
    text: "Đấu thầu hạn chế",
  },
  {
    value: 3,
    text: "Chỉ định thầu",
  },
  {
    value: 4,
    text: "Chào hàng cạnh tranh",
  },
  {
    value: 5,
    text: "Mua sắm trực tiếp",
  },
  {
    value: 6,
    text: "Khác",
  },
];
export const INSURANCE_CONST_GROUP = [
  {
    value: 1,
    text: "Xét nghiệm",
  },
  {
    value: 2,
    text: "Chẩn đoán hình ảnh",
  },
  {
    value: 3,
    text: "Thăm dò chức năng",
  },
];

export const MEDICINE_TYPE = [
  {
    value: 1,
    text: "Tân dược",
  },
  {
    value: 2,
    text: "Chế phẩm",
  },
  {
    value: 3,
    text: "Vị thuốc",
  },
  {
    value: 4,
    text: "Tân dược tự bào chế",
  },
  {
    value: 5,
    text: "Chế phẩm tự bào chế",
  },
];

export const BIDDING_TYPE = [
  {
    value: 1,
    text: "Thầu tập trung",
  },
  {
    value: 2,
    text: "Thầu riêng tại cơ sở KCB",
  },
  {
    value: 3,
    text: "Tự pha chế,bào chế, chế biến",
  },
  {
    value: 4,
    text: "Mua sắm theo kết quả trúng thầu của đơn vị khác",
  },
];

export const BIDDING_FORM = [
  {
    value: 1,
    text: "Đấu thầu rộng rãi",
  },
  {
    value: 2,
    text: "Đấu thầu hạn chế",
  },
  {
    value: 3,
    text: "Chỉ định thầu",
  },
  {
    value: 4,
    text: "Chào hàng cạnh tranh",
  },
  {
    value: 5,
    text: "Mua sắm trực tiếp",
  },
  {
    value: 6,
    text: "Khác",
  },
];

export const RECONSTITUTION = [
  {
    value: 1,
    text: "Nước vô trùng (Bacteriostatic Water for Injection (BWFI)",
  },
  {
    value: 2,
    text: "Nước muối sinh lý 0.9% dung trong tiêm truyền(0.9% Sodium Chloride Injection)",
  },
  {
    value: 3,
    text: "Đường đẳng trương 5% (Glucose 5% hoặc Dextrose 5%)",
  },
];

export const DILUTION = [
  {
    value: 1,
    text: "Nước muối sinh lý 0.9% dung trong tiêm truyền(0.9% Sodium Chloride Injection)",
  },
  {
    value: 2,
    text: "Đường đẳng trương 5% (Glucose 5% hoặc Dextrose 5%)",
  },
];

export const CATE_OPTIONS = {
  USETYPE: "use_type",
};

export const GROUP_MGNT = [
  {
    value: 1,
    text: "Thuốc gây nghiện",
  },
  {
    value: 2,
    text: "Thuốc hướng thần",
  },
  {
    value: 3,
    text: "Thuốc tiền chất",
  },
  {
    value: 4,
    text: "Thuốc dạng phối hợp",
  },
];

export const GROUP_RESEARCH = [
  {
    value: 1,
    text: "Khoa học các phương pháp phân tích",
  },
  {
    value: 2,
    text: "Khoa học sức khoẻ môi trường",
  },
  {
    value: 3,
    text: "Khoa học dược học/các hợp chất thiên nhiên",
  },
];

export const GROUP_PATHOLOGICAL = [
  {
    value: 1,
    text: "Nhóm rối loạn chuyển hoá",
  },
  {
    value: 2,
    text: "Nhóm hệ thống hô hấp, phổi",
  },
  {
    value: 3,
    text: "Nhóm liên quan đến tim mạch",
  },
];

export const GROUP_ABCVEN = [
  {
    value: 1,
    text: "Nhóm A",
  },
  {
    value: 2,
    text: "Nhóm B",
  },
  {
    value: 3,
    text: "Nhóm C",
  },
  {
    value: 4,
    text: "Nhóm D",
  },
  {
    value: 5,
    text: "Nhóm E",
  },
  {
    value: 6,
    text: "Nhóm F",
  },
];

export const GROUP_PHARMACOLOGICAL = [
  {
    value: 1,
    text: "Nhóm thuốc kháng sinh",
  },
  {
    value: 2,
    text: "Nhóm thuốc hạ sốt - giảm đau - chống viêm",
  },
  {
    value: 3,
    text: "Nhóm chống viêm",
  },
];

export const GROUP_SUB_PHARMACOLOGICAL = [
  {
    value: 1,
    text: "Beta-Lactam",
  },
  {
    value: 2,
    text: "Macrolide",
  },
  {
    value: 3,
    text: "Aminoglycosid",
  },
];

export const exportEndpointAdd = (type) => {
  switch (type) {
    case CATE_OPTIONS.USETYPE:
      return "pharmaUseType/addUseType";
    //Todo
    case y:
      return "";
    default:
    // code block
  }
};
